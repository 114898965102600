export const LISTER_ARBORESCENCE_PROCESSUS = 'processus/LISTER_ARBORESCENCE_PROCESSUS';
export const LISTER_PROCESSUS = 'processus/LISTER_PROCESSUS';
export const LISTER_PROCESSUS_SOUS_PROCESSUS = 'processus/LISTER_PROCESSUS_SOUS_PROCESSUS';
export const UPDATE_PROCESSUS = 'processus/UPDATE_PROCESSUS';
export const LIRE_PROCESSUS = 'processus/LIRE_PROCESSUS';
export const TOGGLE_NODE = 'processus/TOGGLE_NODE';

export const LISTER_REGLE_GESTION = 'rg/LISTER_REGLE_GESTION';
export const ENREGISTRER_REGLE_GESTION = 'rg/ENREGISTRER_REGLE_GESTION';
export const SUPPRIMER_REGLE_GESTION = 'rg/SUPPRIMER_REGLE_GESTION';

export const LISTER_TACHES = 'tache/LISTER_TACHES';
export const LISTER_TACHES_NON_LIEE = 'tache/LISTER_TACHES_NON_LIEE';
export const LISTER_TACHES_PAR_ACTEUR = 'tache/LISTER_TACHES_PAR_ACTEUR';
export const LISTER_TACHES_LIEE = 'tache/LISTER_TACHES_LIEE';
export const ENREGISTRER_TACHE = 'tache/ENREGISTRER_TACHE';
export const SUPPRIMER_TACHE = 'tache/SUPPRIMER_TACHE';

export const LIRE_GENERAL = 'procedure/LIRE_GENERAL';
export const ENREGISTRER_GENERAL = 'procedure/ENREGISTRER_GENERAL';
export const GENERER_MANUEL_PROCEDURE = 'procedure/GENERER_MANUEL_PROCEDURE';
export const RECHERCHER_PROCEDURE_GENERAL = 'procedure/RECHERCHER_PROCEDURE_GENERAL';

export const ENREGISTRER_ETAPE = 'etape/ENREGISTRER_ETAPE';
export const LISTER_ETAPE = 'etape/LISTER_ETAPE';
export const SUPPRIMER_ETAPE = 'etape/SUPPRIMER_ETAPE';
