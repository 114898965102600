import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// hooks
import useAuth from './hooks/useAuth';

// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import Toast from './components/Toast';
import { getUser } from './redux/slices/Auth/Auth.selectors';
import { refreshToken } from './redux/slices/Auth/Auth.actions';

// ----------------------------------------------------------------------

export default function App() {
  const dispatch = useDispatch();
  const { isInitialized } = useAuth();
  const utilisateur = useSelector((state) => getUser(state));
  const [appLaunch, setAppLaunch] = useState(false);

  useEffect(() => {
    if (utilisateur && utilisateur.refreshToken && !appLaunch) {
      dispatch(refreshToken());
      setAppLaunch(true);
    }
  }, [utilisateur, dispatch, appLaunch]);

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <Toast />
              <Settings />
              <ScrollToTop />
              <GoogleAnalytics />
              {isInitialized ? <Router /> : <LoadingScreen />}
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
