const defaultStatus = {
  fetching: false,
  content: undefined
};

export const getApiStatus = (state, name) => state.api[name] || defaultStatus;

export const getIsFetching = (state, name) => getApiStatus(state, name).fetching;

export const getIsFetched = (state, name) => getApiStatus(state, name).success;

export const getContent = (state, name) => getApiStatus(state, name).content;

export const getErrorsMessages = (state) =>
  Object.keys(state.api)
    .filter((name) => state.api[name].message)
    .map((name) => ({
      api: name,
      message: state.api[name].message,
      success: state.api[name].success
    }));
