import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    { path: '/', element: <Navigate to="/dashboard/app" replace /> },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'app', element: <GeneralApp /> },
        { path: 'rh', element: <RessourcesHumaines /> },
        { path: 'actifs', element: <Actifs /> },
        {
          path: 'procedures',
          element: <Procedures />
        },
        { path: 'procedures/:numero', element: <ProcedureDetails /> },
        {
          path: 'processus',
          element: <Processus />
        },
        {
          path: 'processus/:numero',
          element: <ProcessusDetail />
        },
        { path: 'risques', element: <Risques /> },
        { path: 'acteur-tache', element: <ActeurTache /> },
        { path: 'planAction', element: <PlanAction /> },
        { path: 'utilisateurs', element: <Utilisateurs /> },
        { path: 'structures', element: <Structures /> },
        { path: 'parametres', element: <Parametres /> }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <LandingPage /> },
        { path: 'contact-us', element: <Contact /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const RessourcesHumaines = Loadable(lazy(() => import('../pages/rh/RessourcesHumaines')));
const Actifs = Loadable(lazy(() => import('../pages/actifs/Actifs')));
const Processus = Loadable(lazy(() => import('../pages/processus/Processus')));
const ProcessusDetail = Loadable(lazy(() => import('../pages/processus/ProcessusConsult')));
const Procedures = Loadable(lazy(() => import('../pages/procedures/Procedures')));
const ProcedureDetails = Loadable(lazy(() => import('../pages/processus/detail/ProcessusDetail')));
const Risques = Loadable(lazy(() => import('../pages/risques/Risques')));
const ActeurTache = Loadable(lazy(() => import('../pages/acteurTache/ActeurTache')));
const PlanAction = Loadable(lazy(() => import('../pages/planAction/PlanAction')));
const Utilisateurs = Loadable(lazy(() => import('../pages/authentication/utilisateurs/Utilisateurs')));
const Structures = Loadable(lazy(() => import('../pages/authentication/structures/Structures')));
const Parametres = Loadable(lazy(() => import('../pages/parametres/Parametres')));
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
